<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";

import { hasPermission } from "@telia/b2b-logged-in-service";

import useDnmsData from "./composables/dnms-state";

import { translateMixin, translateSetup } from "./locale";

import { logError } from "./utils/logerror";

import Heading from "./components/heading.vue";
import Breadcrumbs from "./components/breadcrumbs.vue";
import MonitoringTables from "./components/monitoring-tables.vue";
import ErrorNotification from "./components/error-notification.vue";
import StatusNotification from "./components/status-notification.vue";
import OrganizationSelector from "./components/organization-selector.vue";
import NoAccessNotification from "./components/no-access-notification.vue";
import NotificationSummaryModal from "./components/notification-summary-modal.vue";
import SubmitNotificationService from "./components/submit-notification-service.vue";
import NotificationMethodsCheckbox from "./components/notification-methods-checkbox.vue";
import NotificationTriggersCheckbox from "./components/notification-triggers-checkbox.vue";

onBeforeMount(async () => {
  setupTranslations();
  await checkLoggedInUserPermissions();
  if (showAccessError.value) return;
  initialize();
});

const t = translateMixin.methods.t;
const reRenderKey = ref(0);
const showAccessError = ref(false);

const { layout, initialize, openModal } = useDnmsData();

const setupTranslations = async () => {
  await translateSetup(["mybusiness"]);
  reRenderKey.value++;
};

const pageError = computed(() => {
  return layout.value.page.error;
});

const showStatusNotification = computed(() => {
  return layout.value.submitStatus.success || layout.value.submitStatus.error;
});

const checkLoggedInUserPermissions = async (): Promise<void> => {
  const NOTIFICATIONS_READ_ACCESS = "READ_DATACOM_NOTIFICATIONS";
  const NOTIFICATIONS_WRITE_ACCESS = "WRITE_DATACOM_NOTIFICATIONS";
  try {
    showAccessError.value = !(
      (await hasPermission(NOTIFICATIONS_READ_ACCESS)) &&
      (await hasPermission(NOTIFICATIONS_WRITE_ACCESS))
    );
  } catch {
    layout.value.page.error = true;
    logError("Failed to fetch logged in users accesses");
  }
};
</script>

<template>
  <div t-id="b2b-datacom-notification-service" :key="reRenderKey">
    <telia-grid>
      <breadcrumbs v-if="!showAccessError" />
      <error-notification v-if="pageError" />
      <no-access-notification v-else-if="showAccessError" />
      <template v-else>
        <heading />
        <status-notification v-if="showStatusNotification" />
        <telia-form name="notificationServiceForm" @submit.prevent="openModal">
          <div class="checkboxes">
            <notification-triggers-checkbox />
            <notification-methods-checkbox />
          </div>
          <organization-selector />
          <monitoring-tables />
          <submit-notification-service />
        </telia-form>
      </template>
    </telia-grid>
    <notification-summary-modal />
  </div>
</template>

<style lang="scss" scoped>
.checkboxes {
  display: flex;
  column-gap: 6rem;
}
</style>
