<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../../locale";

import { DisplayTable } from "../../services/enums/enums";
import useDnmsData from "../../composables/dnms-state";

const t = translateMixin.methods.t;
const { selectedTableData, layout, setMonitorTableToDisplay } = useDnmsData();

const pageLoading = computed(() => {
  return layout.value.page.loading;
});

const isServiceAgreementTableSelected = computed(() => {
  return layout.value.displayTable === DisplayTable.SERVICE_AGREEMENT_TABLE;
});

const isDatacomSubscriptionsTableSelected = computed(() => {
  return layout.value.displayTable === DisplayTable.DATACOM_SUBSCRIPTIONS_TABLE;
});

const hasSelectedSubscriptions = computed(() => {
  return selectedTableData.value.length > 0 && isDatacomSubscriptionsTableSelected.value;
});

const hasSelectedSlas = computed(() => {
  return selectedTableData.value.length > 0 && isServiceAgreementTableSelected.value;
});

const serviceAgreementAdditionalText = computed(() => {
  return hasSelectedSubscriptions.value
    ? t("mybusiness.datacom_notification_services_radio_button_sla_assistive_text")
    : "";
});

const datacomSubscriptionsAdditionalText = computed(() => {
  return hasSelectedSlas.value
    ? t("mybusiness.datacom_notification_services_radio_button_subscriptions_assistive_text")
    : "";
});
</script>

<template>
  <div>
    <template v-if="pageLoading">
      <div class="radio-legend-skeleton">
        <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      </div>
      <div class="radio-buttons-skeleton">
        <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
        <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      </div>
    </template>
    <template v-else>
      <telia-fieldset t-id="table-selector-radio-buttons" :legend="t('mybusiness.monitor')">
        <div class="radio-buttons-fieldset">
          <telia-radio-button
            name="radio-button"
            :label="t('mybusiness.service_agreement')"
            :checked="isServiceAgreementTableSelected"
            @change="setMonitorTableToDisplay(DisplayTable.SERVICE_AGREEMENT_TABLE)"
            :disabled="hasSelectedSubscriptions"
            :additional="serviceAgreementAdditionalText"
          ></telia-radio-button>
          <telia-radio-button
            name="radio-button"
            :label="t('mybusiness.datacom_subscription')"
            :checked="isDatacomSubscriptionsTableSelected"
            @change="setMonitorTableToDisplay(DisplayTable.DATACOM_SUBSCRIPTIONS_TABLE)"
            :disabled="hasSelectedSlas"
            :additional="datacomSubscriptionsAdditionalText"
          ></telia-radio-button>
        </div>
      </telia-fieldset>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.radio-buttons-fieldset {
  display: flex;

  telia-radio-button + telia-radio-button {
    margin-left: $telia-spacing-16;
  }
}

.radio-legend-skeleton {
  telia-skeleton {
    height: 3rem;
    width: 30rem;
  }
}

.radio-buttons-skeleton {
  display: flex;
  margin-top: $telia-spacing-16;
  margin-bottom: $telia-spacing-16;

  telia-skeleton {
    height: 2rem;
    width: 16rem;
    + telia-skeleton {
      margin-left: $telia-spacing-16;
    }
  }
}
</style>
