<script setup lang="ts">
import { computed } from "vue";
import { translateMixin } from "../../locale";
import useDnmsData from "../../composables/dnms-state";

const t = translateMixin.methods.t;
const {
  datacomSubscriptions,
  layout,
  handlePaginationChangeDatacomSubscriptionsTable,
  rowCheckboxClicked,
} = useDnmsData();

const tableHeaders = computed(() => {
  return [
    {
      title: `${t("mybusiness.id")}/${t("mybusiness.caseTable.column.subscriptionAlias")}`,
      type: "text",
    },
    { title: t("mybusiness.product"), type: "text" },
    { title: t("mybusiness.service"), type: "text" },
    { title: t("mybusiness.form.label.address"), type: "text" },
    { title: t("mybusiness.invoice.organisation"), type: "text" },
  ];
});

const checkboxOptions = computed(() => {
  return { headerEnabled: false, rowsEnabled: true, headerText: t("mybusiness.select") };
});

const loading = computed(() => {
  return layout.value.datacomSubscriptions.loading || layout.value.page.loading;
});

const error = computed(() => {
  return layout.value.datacomSubscriptions.error;
});

const emptyList = computed(() => {
  return rowData.value.length === 0;
});

const tableMessage = computed(() => {
  if (error.value) {
    return t("mybusiness.datacom_failed_to_fetch_service_list");
  }

  if (emptyList.value) {
    return t("mybusiness.datacom_notification_empty_subscriptions_list");
  }

  return "";
});

const rowData = computed(() => {
  return datacomSubscriptions.value.list.map((item) => [
    item.checked,
    createIdColumnText(item.id, item.alias),
    item.product,
    item.service,
    formatAddressString(item.address),
    item.organizationName,
  ]);
});

const pagination = computed(() => {
  return datacomSubscriptions.value.pagination;
});

const pagesizes = computed(() => {
  return [10, 25, 50];
});

const showPaginator = computed(() => {
  return pagination.value.totalCount > pagesizes.value[0];
});

const handleRowCheckboxClicked = (event: CustomEvent) => {
  rowCheckboxClicked(event.detail);
};

const formatAddressString = (address: string) => {
  if (!address) return address;
  return address
    .split(" ")
    .map((word) => {
      if (!word[0]) return word;
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
};

const handlePaginationChange = (event: CustomEvent) => {
  handlePaginationChangeDatacomSubscriptionsTable(event.detail);
};

const createIdColumnText = (id: string, alias?: string) => {
  return alias ? `${id} / ${alias}` : `${id}`;
};
</script>

<template>
  <div t-id="datacom-subscriptions-table-component">
    <b2x-table
      :columns="JSON.stringify(tableHeaders)"
      :data="JSON.stringify(rowData)"
      @rowCheckboxClicked="handleRowCheckboxClicked"
      :is-select-row-checkbox="JSON.stringify(checkboxOptions)"
      :message="tableMessage"
      :is-loading="loading"
    />
    <b2x-paginator
      v-if="showPaginator"
      :list-length="pagination.totalCount"
      :page-sizes="JSON.stringify(pagesizes)"
      :default-page-size="pagination.pagesize"
      @paginationChange="handlePaginationChange"
    />
  </div>
</template>

<style lang="scss" scoped></style>
