<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../../locale";

import { DisplayTable } from "../../services/enums/enums";
import useDnmsData from "../../composables/dnms-state";

const t = translateMixin.methods.t;
const { filterOnSelected, layout, checkedRowsFilterChanged } = useDnmsData();

const pageLoading = computed(() => {
  return layout.value.page.loading;
});

const options = computed(() => {
  return [
    {
      label: showAllRowsLabel.value,
      value: "ALL",
    },
    {
      label: showSelectedRowsLabel.value,
      value: "CHECKED",
    },
  ];
});

const isServiceAgreementTable = computed(() => {
  return layout.value.displayTable === DisplayTable.SERVICE_AGREEMENT_TABLE;
});

const showAllRowsLabel = computed(() => {
  return isServiceAgreementTable.value
    ? t("mybusiness.show_all_service_agreement")
    : t("mybusiness.show_all_subscriptions");
});

const showSelectedRowsLabel = computed(() => {
  return isServiceAgreementTable.value
    ? t("mybusiness.show_selected_service_agreements")
    : t("mybusiness.show_selected_subscriptions");
});

const optionSelected = ({ detail }) => {
  checkedRowsFilterChanged(detail);
};
</script>

<template>
  <div>
    <template v-if="pageLoading"><telia-skeleton t-id="telia-skeleton" /></template>
    <template v-else>
      <b2x-inline-dropdown
        type="text"
        t-id="datacom-table-filter-checked"
        heading-element="telia-p"
        heading-variant="paragraph-100"
        icon-size="sm"
        :value="filterOnSelected"
        :options="JSON.stringify(options)"
        @optionSelected="optionSelected"
      ></b2x-inline-dropdown>
    </template>
  </div>
</template>

<style lang="scss" scoped></style>
