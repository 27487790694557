<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../locale";

import useDnmsData from "../composables/dnms-state";

const t = translateMixin.methods.t;
const { notificationId, layout } = useDnmsData();

const pageLoading = computed(() => {
  return layout.value.page.loading;
});

const headerText = computed(() => {
  return layout.value.newNotificationPage
    ? t("mybusiness.datacom_new_notification_service")
    : `${t("mybusiness.datacom_notification_service")} ${notificationId.value}`;
});
</script>

<template>
  <header>
    <template v-if="pageLoading"><telia-skeleton t-id="telia-skeleton"></telia-skeleton></template>
    <template v-else>
      <telia-heading t-id="page-header" tag="h1" variant="title-300">{{
        headerText
      }}</telia-heading>
    </template>
  </header>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";

header {
  margin: $telia-spacing-32 0;
  telia-skeleton {
    height: 4rem;
    width: 35rem;
  }
}
</style>
