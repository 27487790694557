<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../locale";

import { DisplayTable } from "../services/enums/enums";
import useDnmsData from "../composables/dnms-state";
import { notificationTriggerTranslationsKeys } from "../services/consts/translation_keys";

const t = translateMixin.methods.t;
const {
  selectedTableData,
  contactPerson,
  notificationMethods,
  notificationTriggers,
  layout,
  createNotification,
  closeModal,
} = useDnmsData();

const showModal = computed(() => {
  return layout.value.modal.open;
});

const triggers = computed(() => {
  return notificationTriggers.value.filter((trigger) => trigger.checked);
});

const methods = computed(() => {
  return notificationMethods.value.filter((method) => method.checked);
});

const services = computed(() => {
  return selectedTableData.value;
});

const triggersMissing = computed(() => {
  return triggers.value.length === 0;
});

const methodsMissing = computed(() => {
  return methods.value.length === 0;
});

const servicesMissing = computed(() => {
  return services.value.length === 0;
});

const submitDisabled = computed(() => {
  return triggersMissing.value || methodsMissing.value || servicesMissing.value;
});

const tableSelectedText = computed(() => {
  if (layout.value.displayTable === DisplayTable.DATACOM_SUBSCRIPTIONS_TABLE) {
    return t("mybusiness.subscription").toLowerCase();
  }

  if (layout.value.displayTable === DisplayTable.SERVICE_AGREEMENT_TABLE) {
    return t("mybusiness.service_agreement").toLowerCase();
  }

  return "";
});

const notificationServicesText = computed(() => {
  if (servicesMissing.value) {
    return `- ${t("mybusiness.no_selection_made")}`;
  }

  if (services.value.length > 1) {
    return `${services.value[0]} + ${services.value.length - 1} ${tableSelectedText.value}`;
  }

  return `${services.value[0]}`;
});

const submitStatusLoading = computed(() => {
  return layout.value.submitStatus.loading;
});

const loadingLabel = computed(() => {
  return layout.value.newNotificationPage
    ? t("modal.buttons.new.submitting")
    : t("modal.buttons.update.submitting");
});

const getTriggerKeyTranslated = (notificationTriggerKey: string) => {
  return t(notificationTriggerTranslationsKeys[notificationTriggerKey]);
};

const getMethodKeyTranslated = (notificationMethodKey: NotificationMethodKey) => {
  switch (notificationMethodKey) {
    case "SEND_EMAIL":
      return `${t("mybusiness.email_to")} ${contactPerson.value.email}`;
    case "SEND_SMS":
      return `${t("mybusiness.sms_to")} ${contactPerson.value.mobilePhone}`;
    default:
      return "";
  }
};

const submitForm = () => {
  createNotification();
};
</script>

<template>
  <b2x-modal modal-id="notification-summary-modal" @closeModal="closeModal" :is-open="showModal">
    <div slot="modal-body" class="wrapper">
      <div class="error-notification">
        <telia-notification v-if="submitDisabled" status="error" heading-tag="h2">
          <span slot="heading">{{ t("modal.header") }}</span>
          <telia-text-spacing slot="content">
            <telia-p>{{ t("modal.content") }}</telia-p>
          </telia-text-spacing>
        </telia-notification>
      </div>
      <div t-id="notification-triggers" class="list">
        <telia-heading tag="h2" variant="subsection-100">{{
          t("mybusiness.following_events")
        }}</telia-heading>
        <telia-list listTag="ul" variant="column">
          <li v-for="trigger in triggers" :key="trigger.key">
            <telia-p variant="paragraph-200">{{ getTriggerKeyTranslated(trigger.key) }}</telia-p>
          </li>
        </telia-list>
        <telia-p v-if="triggersMissing">- {{ t("mybusiness.no_selection_made") }}</telia-p>
      </div>

      <div t-id="notification-methods" class="list">
        <telia-heading tag="h2" variant="subsection-100">{{
          t("mybusiness.datacom_send_notifications_as")
        }}</telia-heading>
        <telia-list listTag="ul" variant="column">
          <li v-for="method in methods" :key="method.key">
            <telia-p variant="paragraph-200">{{ getMethodKeyTranslated(method.key) }}</telia-p>
          </li>
        </telia-list>
        <telia-p v-if="methodsMissing">- {{ t("mybusiness.no_selection_made") }}</telia-p>
      </div>

      <div t-id="notification-services" class="list">
        <telia-heading tag="h2" variant="subsection-100">{{
          t("mybusiness.monitor")
        }}</telia-heading>
        <telia-p variant="paragraph-200">{{ notificationServicesText }}</telia-p>
      </div>
    </div>
    <div slot="button-footer" class="button-footer">
      <template v-if="submitDisabled">
        <telia-button t-id="invalid-button" variant="primary" @click="closeModal" type="button">{{
          t("mybusiness.GOT_IT")
        }}</telia-button></template
      >
      <template v-else>
        <telia-button
          t-id="cancel-button"
          variant="tertiary-purple"
          @click="closeModal"
          type="button"
          :disabled="submitStatusLoading"
          >{{ t("mybusiness.cancel") }}</telia-button
        >
        <b2x-loading-button
          t-id="confirm-button"
          variant="primary"
          type="button"
          @click="submitForm"
          :is-loading="submitStatusLoading"
          :loading-label="loadingLabel"
          >{{ t("mybusiness.confirm") }}</b2x-loading-button
        >
      </template>
    </div>
  </b2x-modal>
</template>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables.scss";

.wrapper {
  margin: $telia-spacing-24;
}

.error-notification {
  margin-bottom: $telia-spacing-12;
}

.list {
  + .list {
    margin-top: 1rem;
  }
}

.button-footer {
  display: flex;
  justify-content: flex-end;
  margin: 0 $telia-spacing-24 $telia-spacing-24 $telia-spacing-24;

  telia-button + telia-button {
    margin-left: 1rem;
  }
}
</style>
