<script setup lang="ts">
import { computed, ref } from "vue";
import { translateMixin } from "../../locale";
import useDnmsData from "../../composables/dnms-state";

const t = translateMixin.methods.t;
const reRenderKey = ref(0);
const {
  serviceAgreement,
  layout,
  handlePaginationChangeServiceAgreementTable,
  rowCheckboxClicked,
} = useDnmsData();

const tableHeaders = computed(() => {
  return [
    { title: t("mybusiness.id"), type: "text" },
    { title: t("mybusiness.invoice.organisation"), type: "text" },
  ];
});

const checkboxOptions = computed(() => {
  return { headerEnabled: false, rowsEnabled: true, headerText: t("mybusiness.select") };
});

const loading = computed(() => {
  return layout.value.serviceAgreement.loading || layout.value.page.loading;
});

const error = computed(() => {
  return layout.value.serviceAgreement.error;
});

const tableMessage = computed(() => {
  if (error.value) {
    return t("mybusiness.datacom_failed_to_fetch_service_list");
  }

  if (listLength.value === 0) {
    return t("mybusiness.datacom_notification_empty_service_agreement_list");
  }

  return "";
});

const rowData = computed(() => {
  return serviceAgreement.value.displayList;
});

const listLength = computed(() => {
  return serviceAgreement.value.pagination.listLength;
});

const pagesize = computed(() => {
  return serviceAgreement.value.pagination.pagesize;
});

const pagesizes = computed(() => {
  return [10, 25, 50];
});

const showPaginator = computed(() => {
  return listLength.value > pagesizes.value[0];
});

const handleRowCheckboxClicked = (event) => {
  reRenderKey.value++;
  rowCheckboxClicked(event.detail);
};

const handlePaginationChange = (event) => {
  handlePaginationChangeServiceAgreementTable(event.detail);
};
</script>

<template>
  <div t-id="service-agreement-table-component">
    <b2x-table
      :columns="JSON.stringify(tableHeaders)"
      :data="JSON.stringify(rowData)"
      @rowCheckboxClicked="handleRowCheckboxClicked"
      :is-select-row-checkbox="JSON.stringify(checkboxOptions)"
      :message="tableMessage"
      :is-loading="loading"
      :key="reRenderKey"
    />
    <b2x-paginator
      v-if="showPaginator"
      :list-length="listLength"
      :page-sizes="JSON.stringify(pagesizes)"
      :default-page-size="pagesize"
      @paginationChange="handlePaginationChange"
    />
  </div>
</template>

<style lang="scss" scoped></style>
