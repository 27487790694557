<script setup lang="ts">
import { computed } from "vue";

import tableSelectors from "./monitoring-tables-components/table-selectors.vue";
import ServiceAgreementTable from "./monitoring-tables-components/service-agreement-table.vue";
import TableCheckedRowsFilter from "./monitoring-tables-components/table-checked-rows-filter.vue";
import DatacomSubscriptionsTable from "./monitoring-tables-components/datacom-subscriptions-table.vue";

import { translateMixin } from "../locale";

import { DisplayTable } from "../services/enums/enums";
import useDnmsData from "../composables/dnms-state";

const t = translateMixin.methods.t;
const { layout } = useDnmsData();

const tableToRender = computed(() => {
  return layout.value.displayTable === DisplayTable.DATACOM_SUBSCRIPTIONS_TABLE
    ? DatacomSubscriptionsTable
    : ServiceAgreementTable;
});
</script>

<template>
  <section class="monitoring-tables-container">
    <table-selectors />
    <table-checked-rows-filter />
    <div>
      <component :is="tableToRender" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables.scss";

.monitoring-tables-container {
  margin: $telia-spacing-32 0;
}
</style>
