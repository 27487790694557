import { corpCustomerDatacomMessaging } from "@telia/b2b-rest-client";

export type NotificationRequestDTO = corpCustomerDatacomMessaging.NotificationRequestDTO;
export type NotificationsResponseDTO = corpCustomerDatacomMessaging.NotificationsResponseDTO;
export type SlaCountUI = corpCustomerDatacomMessaging.SlaCountUI;
export type NotificationResponseDTO = corpCustomerDatacomMessaging.NotificationResponseDTO;

export const getContactPerson = (
  scopeId: string,
  tscid: string,
  personId: number
): Promise<corpCustomerDatacomMessaging.GetPersonResponseDTO> => {
  return new Promise<corpCustomerDatacomMessaging.GetPersonResponseDTO>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.getPerson(
      scopeId,
      tscid,
      personId
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getSlaCount = (
  scopeId: string
): Promise<corpCustomerDatacomMessaging.SlaCountUI[]> => {
  return new Promise<corpCustomerDatacomMessaging.SlaCountUI[]>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.getSlaCount(scopeId)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getPaginatedDatacomSubscriptionList = (
  limit: number,
  offset: number,
  scopeId: string,
  filter?: string[] | undefined,
  searchString?: string | undefined,
  sort?: string | undefined
): Promise<DatacomSubscriptionResponse> => {
  return new Promise<DatacomSubscriptionResponse>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.getPaginatedDatacomSubscriptionList(
      scopeId,
      limit,
      offset,
      searchString,
      filter,
      sort
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const createNotification = (
  scopeId: string,
  tscid: string,
  personId: number,
  payload: corpCustomerDatacomMessaging.NotificationRequestDTO
): Promise<corpCustomerDatacomMessaging.NotificationResponseDTO> => {
  return new Promise<corpCustomerDatacomMessaging.NotificationResponseDTO>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.createNotification(
      scopeId,
      tscid,
      personId,
      payload
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const updateNotification = (
  scopeId: string,
  tscid: string,
  personId: number,
  notificationId: number,
  payload: corpCustomerDatacomMessaging.NotificationRequestDTO
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.updateNotification(
      scopeId,
      tscid,
      personId,
      notificationId,
      payload
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getNotification = (
  scopeId: string,
  tscid: string,
  personId: number
): Promise<corpCustomerDatacomMessaging.NotificationsResponseDTO> => {
  return new Promise<corpCustomerDatacomMessaging.NotificationsResponseDTO>((resolve, reject) => {
    corpCustomerDatacomMessaging.CorpCustomerDatacomMessagingService.getNotification(
      scopeId,
      tscid,
      personId
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
