<script setup lang="ts">
import { computed, ref } from "vue";

import { addNewToast } from "@telia/b2b-message-service";

import { translateMixin } from "../locale";

import useDnmsData from "../composables/dnms-state";

const t = translateMixin.methods.t;
const { selectedTableData, organizations, layout, setSelectedOrganization } = useDnmsData();
const toastId = ref(0);
const swappingOrganisationWarningShown = ref(false);

const pageLoading = computed(() => {
  return layout.value.page.loading;
});

const organizationsSelectorOptions = computed(() => {
  let orgList = organizations.value.list.map((org) => {
    return { label: org.name, value: org.number };
  });
  orgList.unshift({
    label: t("mybusiness.invoice.allOrganisations"),
    value: "",
  });
  return orgList;
});

const shouldShowDropDown = computed(() => {
  return organizations.value.list.length > 1;
});

const selectedOrganization = computed(() => {
  return organizations.value.selected.number;
});

const hasSelectedTableData = computed(() => {
  return selectedTableData.value.length > 0;
});

const changeOrganisation = (value: CustomEvent) => {
  setSelectedOrganization(value.detail);
};

const handleClick = () => {
  if (hasSelectedTableData.value && !swappingOrganisationWarningShown.value) {
    addNewToast(
      `org-warn-toast-${toastId.value++}`,
      "warning",
      t("swappingOrganisationWarning"),
      ""
    );
    swappingOrganisationWarningShown.value = true;
  }
};
</script>

<template>
  <div v-if="shouldShowDropDown || pageLoading">
    <template v-if="pageLoading"><telia-skeleton t-id="telia-skeleton" /></template>
    <template v-else>
      <b2x-inline-dropdown
        type="text"
        t-id="datacom-organization-selector"
        heading-element="telia-p"
        heading-variant="paragraph-100"
        icon-size="sm"
        :value="selectedOrganization"
        :options="JSON.stringify(organizationsSelectorOptions)"
        @optionSelected="changeOrganisation"
        @click="handleClick"
      ></b2x-inline-dropdown>
    </template>
  </div>
</template>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/variables.scss";
telia-skeleton {
  height: 4.2rem;
  width: 19rem;
}
</style>
