import { ref } from "vue";

import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { getOrganizations } from "@telia/b2b-logged-in-service";

import { DisplayTable } from "../services/enums/enums";
import {
  createContactPerson,
  createDatacomSubscriptions,
  createLayout,
  createNotificationMethods,
  createNotificationTriggers,
  createOrganizations,
  createServiceAgreement,
} from "../services/consts/create_state";
import {
  NotificationRequestDTO,
  NotificationsResponseDTO,
  SlaCountUI,
  createNotification,
  getContactPerson,
  getNotification,
  getPaginatedDatacomSubscriptionList,
  getSlaCount,
  updateNotification,
} from "../services/corp-customer-datacom-messaging";

import { logError } from "../utils/logerror";
import { trackEvent, analytics } from "../utils/analytics";

const scopeId = ref("");
const notificationId = ref(0);
const selectedTableData = ref<string[]>([]);
const organizations = ref(createOrganizations());
const contactPerson = ref(createContactPerson());
const notificationTriggers = ref(createNotificationTriggers());
const notificationMethods = ref(createNotificationMethods());
const serviceAgreement = ref(createServiceAgreement());
const datacomSubscriptions = ref(createDatacomSubscriptions());
const filterOnSelected = ref<"ALL" | "CHECKED">("ALL");
const layout = ref(createLayout());

const useDnmsData = () => {
  const initialize = async () => {
    await _initializeData();
  };

  const setMonitorTableToDisplay = (selectMonitorTable: DisplayTable): void => {
    layout.value.displayTable = selectMonitorTable;
    if (selectMonitorTable === DisplayTable.DATACOM_SUBSCRIPTIONS_TABLE) {
      _tryToFetchDatacomSubscriptionList();
    } else {
      _tryToFetchServiceAgreement();
    }

    trackEvent(selectMonitorTable, analytics.action.CLICK, layout.value.newNotificationPage);
  };

  const toggleNotificationTriggerChecked = (
    notificationTriggerKey: NotifactionTriggerKey
  ): void => {
    _toggleNotificationTriggerChecked(notificationTriggerKey);
  };

  const toggleNotificationMethodChecked = (notificationMethodKey: NotificationMethodKey): void => {
    _toggleNotificationMethodChecked(notificationMethodKey);
  };

  const handlePaginationChangeDatacomSubscriptionsTable = (detail: {
    page: number;
    pageSize: number;
  }): void => {
    _tablePaginationChangeGoogleAnalytics(detail);
    _changePageDatacomSubscriptionsTable(detail.page, detail.pageSize);
  };

  const handlePaginationChangeServiceAgreementTable = (detail: {
    page: number;
    pageSize: number;
  }): void => {
    _tablePaginationChangeGoogleAnalytics(detail);
    _changePageServiceAgreementTable(detail.page, detail.pageSize);
  };

  const rowCheckboxClicked = (rowNumber: number): void => {
    switch (layout.value.displayTable) {
      case DisplayTable.SERVICE_AGREEMENT_TABLE:
        _addOrRemoveServiceAgreementFromSelectedData(rowNumber);
        break;
      case DisplayTable.DATACOM_SUBSCRIPTIONS_TABLE:
        _addOrRemoveDatacomSubscriptionsFromSelectedData(rowNumber);
        break;
      default:
        break;
    }
  };

  const openModal = (): void => {
    layout.value.modal.open = true;
    trackEvent(
      analytics.label.SELECTION_SUMMARY_MODAL,
      analytics.action.OPEN_MODAL,
      layout.value.newNotificationPage
    );
  };

  const closeModal = (): void => {
    layout.value.modal.open = false;
    trackEvent(
      analytics.label.SELECTION_SUMMARY_MODAL,
      analytics.action.CLOSE_MODAL,
      layout.value.newNotificationPage
    );
  };

  const createNotification = (): void => {
    _tryToCreateNotification();
  };

  const setSelectedOrganization = (orgNumber: string): void => {
    _clearSelectedTableData();
    organizations.value.selected = organizations.value.list.find(
      (org) => org.number === orgNumber
    ) ?? { number: "", name: "", tscid: "" };

    _filterSelectedTable();
    trackEvent(
      analytics.label.ORGANIZATION_SELECTED,
      analytics.action.CLICK,
      layout.value.newNotificationPage
    );
  };

  const checkedRowsFilterChanged = (detail: "ALL" | "CHECKED"): void => {
    filterOnSelected.value = detail;
    _filterSelectedTable();
  };

  return {
    scopeId,
    notificationId,
    organizations,
    contactPerson,
    serviceAgreement,
    datacomSubscriptions,
    notificationTriggers,
    notificationMethods,
    selectedTableData,
    filterOnSelected,
    layout,
    initialize,
    setMonitorTableToDisplay,
    toggleNotificationTriggerChecked,
    toggleNotificationMethodChecked,
    handlePaginationChangeDatacomSubscriptionsTable,
    handlePaginationChangeServiceAgreementTable,
    rowCheckboxClicked,
    openModal,
    closeModal,
    createNotification,
    setSelectedOrganization,
    checkedRowsFilterChanged,
  };
};

export default useDnmsData;

const _initializeData = async (): Promise<void> => {
  _getDataFromUrl();
  await _setUserData();
  await _tryToFetchContactPerson();

  if (layout.value.newNotificationPage) {
    _tryToFetchServiceAgreement();
  } else {
    _tryToFetchNotification();
  }
};

const _getDataFromUrl = () => {
  contactPerson.value.personId = _getPersonIdFromUrl();
  notificationId.value = _getNotificationIdFromUrl();
  if (notificationId.value > 0) {
    layout.value.newNotificationPage = false;
  }
};

const _setUserData = async (): Promise<void> => {
  scopeId.value = await getScopeIdOrThrow();
  const organizationsResponse = await getOrganizations();
  organizations.value.list = organizationsResponse.map((organization) => ({
    name: organization.name ?? "",
    tscid: organization.tscid ?? "",
    number: organization.organizationNumber ?? "",
  }));
};

const _tryToFetchContactPerson = async (): Promise<void> => {
  try {
    const response = await getContactPerson(
      scopeId.value,
      organizations.value.list[0].tscid,
      contactPerson.value.personId
    );
    contactPerson.value.fullName = response.fullName ?? "";
    contactPerson.value.email = response.email ?? "";
    contactPerson.value.mobilePhone = response.mobilePhone ?? "";
    contactPerson.value.organizationNumber = response.organisationNumber ?? "";
  } catch {
    layout.value.page.error = true;
    logError("Failed to fetch contact person");
  } finally {
    layout.value.page.loading = false;
  }
};

const _tryToFetchDatacomSubscriptionList = async (): Promise<void> => {
  try {
    layout.value.datacomSubscriptions.error = false;
    layout.value.datacomSubscriptions.loading = true;

    const filter: string[] = [];

    if (_hasSelectedOrganization()) {
      filter.push(`subscribername=${organizations.value.selected.name}`);
    }

    if (_filterOnSelectedRows()) {
      filter.push(`subscriptionid=${selectedTableData.value.join(",")}`);
    }

    const response: DatacomSubscriptionResponse = await getPaginatedDatacomSubscriptionList(
      datacomSubscriptions.value.pagination.pagesize,
      _getOffsetDatacomSubscriptions(),
      scopeId.value,
      filter,
      undefined,
      datacomSubscriptions.value.sort
    );

    const { list, totalCount } = _mapDatacomSubscriptions(response);

    datacomSubscriptions.value.list = list;
    datacomSubscriptions.value.pagination.totalCount = totalCount;

    if (_filterOnSelectedRows()) {
      logErrorIfNotificationContainsMissingSubscriptions(response.filteredCount);
    }
  } catch {
    layout.value.datacomSubscriptions.error = true;
    logError("Failed to fetch datacom subscriptions");
  } finally {
    layout.value.datacomSubscriptions.loading = false;
  }
};

const _hasSelectedOrganization = () => {
  return organizations.value.selected.name !== "";
};

const _clearSelectedTableData = () => {
  selectedTableData.value = [];
};

const _mapDatacomSubscriptions = (
  datacomSubscriptions: DatacomSubscriptionResponse
): GetDatacomSubscriptionsResponse => {
  const list = datacomSubscriptions.results.map((sub) => ({
    checked: selectedTableData.value.includes(sub.id),
    id: sub.attributes.subscriptionid,
    alias: sub.attributes.subscriptionalias,
    product: sub.attributes.offeringname,
    service: sub.attributes.publicservice,
    address: sub.attributes.subscriptionaddress,
    organizationName: sub.organizationName,
    organizationNumber: sub.organizationNumber,
  }));
  const totalCount = datacomSubscriptions.filteredCount;
  return { list, totalCount };
};

const _tryToFetchServiceAgreement = async (): Promise<void> => {
  try {
    layout.value.serviceAgreement.error = false;
    layout.value.serviceAgreement.loading = true;
    const slaResponse = await getSlaCount(scopeId.value);

    serviceAgreement.value.list = _mapSlaCount(slaResponse);
    _mapServiceAgreementListToDisplayList();
  } catch (error) {
    layout.value.serviceAgreement.error = true;
    logError("Failed to fetch slaCount");
  } finally {
    layout.value.serviceAgreement.loading = false;
  }
};

const _mapSlaCount = (slaCount: SlaCountUI[]): SlaCount[] => {
  return slaCount.map((item) => ({
    checked: selectedTableData.value.includes(item.sla || ""),
    sla: item.sla ?? "",
    organizationNumber: item.organizationNumber ?? "",
    organizationName: item.organizationName ?? "",
  }));
};

const _tryToFetchNotification = async (): Promise<void> => {
  try {
    const contactPersonOrgTscid = _getOrgTscid(contactPerson.value.organizationNumber);

    const notificationsResponse = await getNotification(
      scopeId.value,
      contactPersonOrgTscid,
      notificationId.value
    );

    if (_includesSlas(notificationsResponse)) {
      layout.value.displayTable = DisplayTable.SERVICE_AGREEMENT_TABLE;
      selectedTableData.value = notificationsResponse.slas ?? [];
      await _tryToFetchServiceAgreement();
      logErrorIfNotificationContainsMissingSlas();
    } else if (_includesSubscriptions(notificationsResponse)) {
      layout.value.displayTable = DisplayTable.DATACOM_SUBSCRIPTIONS_TABLE;
      selectedTableData.value = notificationsResponse.subscriptions ?? [];
      await _tryToFetchDatacomSubscriptionList();
    }
    notificationsResponse.triggers?.forEach((trigger) =>
      _toggleNotificationTriggerChecked(trigger)
    );

    notificationsResponse.actions?.forEach((action) => _toggleNotificationMethodChecked(action));
  } catch (error) {
    layout.value.page.error = true;
    logError("Failed to fetch notification");
  }
};

const _toggleNotificationTriggerChecked = (notificationTriggerKey: NotifactionTriggerKey): void => {
  const index = notificationTriggers.value.findIndex(
    (el: NotificationTrigger) => el.key === notificationTriggerKey
  );
  if (index === -1) {
    logError(`Key not found in notificationTriggers: ${notificationTriggerKey}`);
    return;
  }
  notificationTriggers.value[index].checked = !notificationTriggers.value[index].checked;
};

const _toggleNotificationMethodChecked = (notificationMethodKey: NotificationMethodKey): void => {
  const index = notificationMethods.value.findIndex(
    (el: NotificationMethod) => el.key === notificationMethodKey
  );
  if (index === -1) {
    logError(`Key not found in notificationMethods: ${notificationMethodKey}`);
    return;
  }
  notificationMethods.value[index].checked = !notificationMethods.value[index].checked;
};

const logErrorIfNotificationContainsMissingSlas = (): void => {
  const containsAll: boolean = serviceAgreement.value.list.every((sla) =>
    selectedTableData.value.includes(sla.sla)
  );

  if (!containsAll) logError("Notification contains missing sla");
};

const logErrorIfNotificationContainsMissingSubscriptions = (filteredCount: number): void => {
  const containsAll: boolean = selectedTableData.value.length === filteredCount;

  if (!containsAll) logError("Notification contains missing subscriptions");
};

const _includesSubscriptions = (notificationsResponse: NotificationsResponseDTO) => {
  return notificationsResponse.subscriptions && notificationsResponse.subscriptions.length > 0;
};

const _includesSlas = (notificationsResponse: NotificationsResponseDTO) => {
  return notificationsResponse.slas && notificationsResponse.slas.length > 0;
};

const _tryToCreateNotification = async (): Promise<void> => {
  try {
    layout.value.submitStatus.error = false;
    layout.value.submitStatus.success = false;
    layout.value.submitStatus.loading = true;
    trackEvent(
      analytics.label.SELECTION_SUMMARY_MODAL,
      analytics.action.INITIATED,
      layout.value.newNotificationPage
    );
    const payload: NotificationRequestDTO = _createNotificationPayload();

    const contactPersonOrgTscid = _getOrgTscid(contactPerson.value.organizationNumber);

    layout.value.newNotificationPage
      ? await _callCreateNewNotificationService(payload, contactPersonOrgTscid)
      : await _callUpdateNotificationService(payload, contactPersonOrgTscid);

    layout.value.submitStatus.success = true;
    trackEvent(
      analytics.label.SELECTION_SUMMARY_MODAL,
      analytics.action.COMPLETED,
      layout.value.newNotificationPage
    );
    layout.value.newNotificationPage = false;
  } catch {
    layout.value.submitStatus.error = true;
    trackEvent(
      analytics.label.SELECTION_SUMMARY_MODAL,
      analytics.action.ERROR,
      layout.value.newNotificationPage
    );
  } finally {
    layout.value.submitStatus.loading = false;
    layout.value.modal.open = false;
  }
};

const _callUpdateNotificationService = async (
  payload: NotificationRequestDTO,
  tscid: string
): Promise<void> => {
  await updateNotification(
    scopeId.value,
    tscid,
    contactPerson.value.personId,
    notificationId.value,
    payload
  );
  layout.value.submitStatus.successTranslationKey = "mybusiness.datacom_notification_updated";
};

const _callCreateNewNotificationService = async (
  payload: NotificationRequestDTO,
  tscid: string
): Promise<void> => {
  const response = await createNotification(
    scopeId.value,
    tscid,
    contactPerson.value.personId,
    payload
  );
  notificationId.value = response.notificationId ?? 0;
  layout.value.submitStatus.successTranslationKey = "mybusiness.datacom_notification_created";
};

const _getOrgTscid = (orgNumber: string): string => {
  return organizations.value.list.find((org) => org.number === orgNumber)?.tscid ?? "";
};

const _createNotificationPayload = (): NotificationRequestDTO => {
  const triggers = notificationTriggers.value
    .filter((trigger) => trigger.checked)
    .map((trigger) => trigger.key);

  const actions = notificationMethods.value
    .filter((method) => method.checked)
    .map((method) => method.key);

  let subscriptions: string[] = [];
  let slas: string[] = [];
  if (layout.value.displayTable === DisplayTable.DATACOM_SUBSCRIPTIONS_TABLE) {
    subscriptions = selectedTableData.value;
  } else if (layout.value.displayTable === DisplayTable.SERVICE_AGREEMENT_TABLE) {
    slas = selectedTableData.value;
  }
  return { actions, triggers, subscriptions, slas };
};

const _addOrRemoveDatacomSubscriptionsFromSelectedData = (rowNumber: number): void => {
  const subscription = datacomSubscriptions.value.list[rowNumber];
  if (subscription.checked) {
    subscription.checked = false;
    const index = selectedTableData.value.findIndex((item) => item === subscription.id);
    selectedTableData.value.splice(index, index + 1);
  } else {
    subscription.checked = true;
    selectedTableData.value.push(subscription.id);
  }
};

const _addOrRemoveServiceAgreementFromSelectedData = (rowNumber: number): void => {
  const slaId = serviceAgreement.value.displayList[rowNumber][1];
  const organizationName = serviceAgreement.value.displayList[rowNumber][2];
  const sla = serviceAgreement.value.list.find(
    (sla) => sla.sla === slaId && sla.organizationName === organizationName
  );
  if (sla?.checked) {
    sla.checked = false;
    const index = selectedTableData.value.findIndex((item) => item === sla.sla);
    selectedTableData.value.splice(index, 1);
  } else if (sla) {
    sla.checked = true;
    selectedTableData.value.push(sla.sla);
  } else {
    logError("Sla not found in the serviceAgreement list");
  }
  _mapServiceAgreementListToDisplayList();
};

const _getOffsetDatacomSubscriptions = (): number => {
  return _getOffset(
    datacomSubscriptions.value.pagination.pagesize,
    datacomSubscriptions.value.pagination.page
  );
};

const _getOffsetSlaCountDisplayList = (): number => {
  return _getOffset(
    serviceAgreement.value.pagination.pagesize,
    serviceAgreement.value.pagination.page
  );
};

const _getOffset = (pagesize: number, page: number): number => {
  return pagesize * page - pagesize;
};

const _changePageDatacomSubscriptionsTable = (pageNumber: number, pageSize: number): void => {
  datacomSubscriptions.value.pagination.page = pageNumber;
  datacomSubscriptions.value.pagination.pagesize = pageSize;
  _tryToFetchDatacomSubscriptionList();
};

const _changePageServiceAgreementTable = (pageNumber: number, pageSize: number): void => {
  serviceAgreement.value.pagination.page = pageNumber;
  serviceAgreement.value.pagination.pagesize = pageSize;
  _mapServiceAgreementListToDisplayList();
};

const _filterSelectedTable = (): void => {
  if (layout.value.displayTable === DisplayTable.SERVICE_AGREEMENT_TABLE) {
    serviceAgreement.value.pagination.page = 1;
    _mapServiceAgreementListToDisplayList();
  } else if (layout.value.displayTable === DisplayTable.DATACOM_SUBSCRIPTIONS_TABLE) {
    datacomSubscriptions.value.pagination.page = 1;
    _tryToFetchDatacomSubscriptionList();
  }
};

const _mapServiceAgreementListToDisplayList = (): void => {
  const offset = _getOffsetSlaCountDisplayList();
  const tempList = serviceAgreement.value.list;

  const filteredOnOrganizations = filterListOnSelectedOrganization(tempList);

  const filteredList = _filterListOnSelectedRows(filteredOnOrganizations);
  serviceAgreement.value.pagination.listLength = filteredList.length;

  const paginatedTempList = filteredList.filter(
    (_item, index) => offset <= index && index < serviceAgreement.value.pagination.pagesize + offset
  );
  serviceAgreement.value.displayList = paginatedTempList.map((item) => [
    item.checked,
    item.sla,
    item.organizationName,
  ]);
};

const filterListOnSelectedOrganization = (tempList: SlaCount[]) => {
  if (organizations.value.selected.number !== "") {
    tempList = tempList.filter(
      (item) => item.organizationNumber === organizations.value.selected.number
    );
  }
  return tempList;
};

const _filterListOnSelectedRows = (tempList: SlaCount[]) => {
  if (_filterOnSelectedRows()) {
    tempList = tempList.filter((item) => item.checked);
  }
  return tempList;
};

const _filterOnSelectedRows = () => {
  return filterOnSelected.value === "CHECKED";
};

const _tablePaginationChangeGoogleAnalytics = (detail: {
  page: number;
  pageSize: number;
}): void => {
  const label = _pageSizeHasChanged(detail.pageSize)
    ? `${layout.value.displayTable} - Page size selected ${detail.pageSize}`
    : `${layout.value.displayTable} - Page selected ${detail.page}`;
  trackEvent(label, analytics.action.CLICK, layout.value.newNotificationPage);
};

const _pageSizeHasChanged = (newpageSize: number): boolean => {
  return newpageSize !== datacomSubscriptions.value.pagination.pagesize;
};

const _getPersonIdFromUrl = (): number => {
  const personId = window.location.pathname.split("/")[8];
  return Number(personId);
};

const _getNotificationIdFromUrl = (): number => {
  const notificationId = window.location.pathname.split("/")[9];
  return Number(notificationId) || 0;
};
