<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../locale";

import useDnmsData from "../composables/dnms-state";
import { notificationTriggerTranslationsKeys } from "../services/consts/translation_keys";

const t = translateMixin.methods.t;
const { notificationTriggers, layout, toggleNotificationTriggerChecked } = useDnmsData();

const pageLoading = computed(() => {
  return layout.value.page.loading;
});

const getTranslatedValue = (notificationTriggerKey: NotifactionTriggerKey) => {
  return t(notificationTriggerTranslationsKeys[notificationTriggerKey]);
};
</script>

<template>
  <div class="notification-triggers-checkboxes">
    <template v-if="pageLoading">
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      <telia-skeleton
        t-id="telia-skeleton"
        v-for="(_notificationTrigger, index) in notificationTriggers"
        :key="index"
      ></telia-skeleton>
    </template>
    <template v-else>
      <telia-fieldset
        t-id="notification-triggers-checkbox-fieldset"
        :legend="t('mybusiness.following_events')"
      >
        <telia-checkbox
          v-for="notificationTrigger in notificationTriggers"
          :key="notificationTrigger.key"
          name="notificationTriggers"
          :checked="notificationTrigger.checked"
          @change="() => toggleNotificationTriggerChecked(notificationTrigger.key)"
        >
          {{ getTranslatedValue(notificationTrigger.key) }}
        </telia-checkbox>
      </telia-fieldset>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.notification-triggers-checkboxes {
  telia-skeleton {
    height: 3rem;
    width: 30rem;

    + telia-skeleton {
      margin-top: 1.5rem;
    }
  }
}
</style>
