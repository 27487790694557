import {
  action,
  category,
  label,
  trackEvent as TrackEvent,
} from "@telia/b2b-web-analytics-wrapper";

const ANALYTICS = {
  CATEGORIES: {
    CATEGORY_NEW_PAGE: "Datacom_New_Notification_Service",
    CATEGORY_EDIT_PAGE: "Datacom_Notification_Service",
  },
  ACTION: {
    CLOSE_MODAL: "CloseModal",
  },
  LABELS: {
    SELECTION_SUMMARY_MODAL: "Selection summary modal",
    ORGANIZATION_SELECTED: "Organization selected",
  },
};

export const analytics = {
  action: { ...action, ...ANALYTICS.ACTION },
  category: { ...category, ...ANALYTICS.CATEGORIES },
  label: { ...label, ...ANALYTICS.LABELS },
};

export const trackEvent = (label: string, action: string, newNotificationPage: boolean): void => {
  const category = newNotificationPage
    ? ANALYTICS.CATEGORIES.CATEGORY_NEW_PAGE
    : ANALYTICS.CATEGORIES.CATEGORY_EDIT_PAGE;
  TrackEvent(category, action, label);
};
