<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../locale";

import useDnmsData from "../composables/dnms-state";

const t = translateMixin.methods.t;

const { layout } = useDnmsData();

const pageLoading = computed(() => {
  return layout.value.page.loading;
});

const buttonText = computed(() => {
  return layout.value.newNotificationPage
    ? t("mybusiness.datacom_notification_new_notification_service_button")
    : t("mybusiness.datacom_save_notification_service");
});
</script>

<template>
  <div class="modal-button-wrapper">
    <template v-if="pageLoading">
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
    </template>
    <template v-else>
      <telia-button
        :text="buttonText"
        variant="primary"
        type="submit"
        t-id="modal-button"
      ></telia-button>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.modal-button-wrapper {
  display: flex;
  justify-content: flex-end;

  telia-skeleton {
    height: 3rem;
    width: 30rem;
  }
}
</style>
