<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../locale";

import useDnmsData from "../composables/dnms-state";

const t = translateMixin.methods.t;
const { contactPerson, notificationMethods, layout, toggleNotificationMethodChecked } =
  useDnmsData();

const pageLoading = computed(() => {
  return layout.value.page.loading;
});

const getTranslatedValue = (notificationMethodKey: NotificationMethodKey) => {
  switch (notificationMethodKey) {
    case "SEND_EMAIL":
      return `${t("mybusiness.email_to")} ${contactPerson.value.email}`;
    case "SEND_SMS":
      return `${t("mybusiness.sms_to")} ${contactPerson.value.mobilePhone}`;
    default:
      return "";
  }
};

const toggleColumn = (notificationMethodKey: NotificationMethodKey) => {
  toggleNotificationMethodChecked(notificationMethodKey);
};
</script>

<template>
  <div class="notification-methods-checkboxes">
    <template v-if="pageLoading">
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
      <telia-skeleton t-id="telia-skeleton"></telia-skeleton>
    </template>
    <template v-else>
      <telia-fieldset
        t-id="notification-methods-checkbox-fieldset"
        :legend="t('mybusiness.datacom_send_notifications_as')"
      >
        <telia-checkbox
          name="notificationMethods"
          :checked="notificationMethods[0].checked"
          @change="() => toggleColumn(notificationMethods[0].key)"
        >
          {{ getTranslatedValue(notificationMethods[0].key) }}
        </telia-checkbox>

        <telia-checkbox
          name="notificationMethods"
          :checked="notificationMethods[1].checked"
          @change="() => toggleColumn(notificationMethods[1].key)"
        >
          {{ getTranslatedValue(notificationMethods[1].key) }}
        </telia-checkbox>
      </telia-fieldset>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.notification-methods-checkboxes {
  telia-skeleton {
    height: 3rem;
    width: 30rem;

    + telia-skeleton {
      margin-top: 1.5rem;
    }
  }
}
</style>
