import { DisplayTable } from "../enums/enums";

export const createLayout = (): Layout => ({
  page: {
    loading: true,
    error: false,
  },
  serviceAgreement: {
    loading: false,
    error: false,
  },
  datacomSubscriptions: {
    loading: false,
    error: false,
  },
  displayTable: DisplayTable.SERVICE_AGREEMENT_TABLE,
  modal: {
    open: false,
  },
  submitStatus: {
    success: false,
    error: false,
    loading: false,
    successTranslationKey: "",
  },
  newNotificationPage: true,
});

export const createContactPerson = (): ContactPerson => ({
  personId: 0,
  fullName: "",
  email: "",
  mobilePhone: "",
  organizationNumber: "",
});

export const createOrganizations = (): Organizations => ({
  selected: {
    tscid: "",
    name: "",
    number: "",
  },
  list: [],
});

export const createDatacomSubscriptions = (): DatacomSubscriptions => ({
  list: [],
  pagination: {
    pagesize: 10,
    page: 1,
    totalCount: 0,
  },
  sort: "site",
});

export const createServiceAgreement = (): ServiceAgreement => ({
  list: [],
  displayList: [],
  pagination: {
    pagesize: 10,
    page: 1,
    listLength: 0,
  },
});

export const createNotificationTriggers = (): NotificationTrigger[] => [
  {
    key: "SUBSCRIPTION_IN_TRAFFIC",
    checked: false,
  },
  {
    key: "TICKET_EVENT_CLOSED",
    checked: false,
  },
  {
    key: "TICKET_EVENT_CREATED",
    checked: false,
  },
  {
    key: "TICKET_CREATED_AM03_CO",
    checked: false,
  },
  {
    key: "TICKET_CREATED_AM03_DS",
    checked: false,
  },
  {
    key: "PLANNED_MAINTENANCE",
    checked: false,
  },
];

export const createNotificationMethods = (): NotificationMethod[] => [
  {
    key: "SEND_EMAIL",
    checked: false,
  },
  {
    key: "SEND_SMS",
    checked: false,
  },
];
