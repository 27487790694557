<script setup lang="ts">
import { computed } from "vue";

import { translateMixin } from "../locale";

import useDnmsData from "../composables/dnms-state";

const t = translateMixin.methods.t;
const { layout } = useDnmsData();

const status = computed(() => {
  return layout.value.submitStatus.success ? "success" : "error";
});

const headingText = computed(() => {
  return layout.value.submitStatus.success
    ? t(layout.value.submitStatus.successTranslationKey)
    : t("submit.error");
});
</script>

<template>
  <div class="telia-notification">
    <telia-notification :status="status" heading-tag="h2">
      <span slot="heading">{{ headingText }}</span>
    </telia-notification>
  </div>
</template>

<style lang="scss" scoped></style>
